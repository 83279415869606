<template>
  <div class="logo">
    <Avatar size="small" class="avatar" />
    <div class="label">Maaike</div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.logo {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #1a202c;
  font-size: 1.5rem;
  font-weight: 600;
}

.label {
  margin-top: 0.2rem;
}
</style>
