<template>
  <div class="auth">
    <slot name="logo">
      <NuxtLink to="/" class="logo">
        <ApplicationLogo />
      </NuxtLink>
    </slot>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  flex-grow: 1;
}

.logo {
  margin-bottom: 1rem;
}

.body {
  width: 100%;
}
</style>
